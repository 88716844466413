<!-- @format -->

<template>
  <!-- Sidebar starts -->
  <!-- <div class="">
    
  </div> -->
  <div class="flex flex-no-wrap h-screen bg-lightgrey overflow-hidden">
    <div class="block top-16" :class="{ 'w-16': sidebarOpen }" id="mobile-nav">
      <div
        class="bg-white h-screen shadow overflow-md-hidden overflow-x-hidden transition-width transition-slowest ease flex flex-col"
        id="forHeight"
        :class="[sidebarOpen ? 'w-16' : 'w-64']"
      >
        <!-- <div
          id="openSideBar"
          class="
            h-10
            w-10
            absolute
            flex
            items-center
            justify-center
            cursor-pointer
          "
          :class="[sidebarOpen ? 'left-5' : 'left-52']"
          @click="sidebarHandler(true)"
        >
          <span v-if="!sidebarOpen" class="material-icons text-grey">
            close
          </span>
          <span
            v-else
            class="text-grey text-2xl material-icons transform rotate-180"
            >menu_open
          </span>
        </div> -->
        <div class="text-grey" :class="[sidebarOpen ? 'px-2' : '']">
          <router-link
            :to="{ name: 'practice-select' }"
            :class="{
              'pointer-events-none': assginedPractices === 1,
            }"
          >
            <div class="h-16 w-full mb-12 flex flex-1 items-center">
              <img
                class="w-56 h-11"
                src="@/assets/logos/rushden_horizontal.png"
                alt="Rushden Medical Centre Logo"
              />
            </div>
          </router-link>
          <router-link :to="{ name: 'ma-dashboard' }">
            <span
              class="flex w-full cursor-pointer items-center mb-1"
              :class="[$router.name === 'ma-dashboard' ? 'active-tab' : '']"
            >
              <div class="py-1 px-3 flex items-center justify-center text-xs">
                <span class="material-icons">dashboard </span>
              </div>
              <div class="flex items-end">
                <span class="text-sm" :class="[sidebarOpen ? 'hidden' : '']"
                  >Dashboard</span
                >
              </div>
            </span>
          </router-link>
          <div
            v-for="(category, index) in categories"
            :key="index"
            class="pt-4"
          >
            <div v-if="!sidebarOpen">
              <span
                @click="category.open = !category.open"
                class="flex cursor-pointer items-center mb-1"
                :class="
                  category.open
                    ? 'active-tab bg-teal bg-opacity-10 border border-teal border-l-4 border-t-0 border-b-0 border-r-0 py-1'
                    : 'hover:text-darkGrey'
                "
              >
                <div class="py-1 px-3 flex items-center justify-center text-xs">
                  <span v-if="category.icon" class="material-icons">
                    {{ category.icon }}
                  </span>
                </div>

                <div class="flex w-48 items-end">
                  <span
                    class="text-sm"
                    :class="[sidebarOpen ? 'hidden' : '']"
                    >{{ category.name }}</span
                  >
                </div>
                <div class="py-1 px-3 flex items-end justify-end">
                  <span class="material-icons">
                    {{
                      category.open
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"
                    }}
                  </span>
                </div>
              </span>
            </div>
            <div v-else>
              <span
                @click="category.open = !category.open"
                class="flex w-full cursor-pointer items-center mb-1 hover:text-darkGrey"
              >
                <router-link
                  v-if="!category.query"
                  :to="{ name: category.link }"
                  :class="category.open ? 'active-tab' : ''"
                >
                  <div
                    class="py-1 px-3 flex items-center justify-center text-xs"
                  >
                    <span class="material-icons"> {{ category.icon }} </span>
                  </div>
                </router-link>
                <router-link
                  v-else
                  :to="{
                    name: category.link,
                    query: { activeTab: category.query },
                  }"
                >
                  <div
                    class="py-1 px-3 flex items-center justify-center text-xs"
                  >
                    <span class="material-icons"> {{ category.icon }} </span>
                  </div>
                </router-link>
              </span>
            </div>
            <div v-if="category.open">
              <div
                v-for="(item, idx) in category.items"
                :key="idx"
                class="pt-4 px-4 ml-2"
              >
                <router-link
                  :to="{ name: item.link, params: item.params || {} }"
                >
                  <span
                    class="flex cursor-pointer items-center mb-1 hover:text-darkGrey"
                  >
                    <div
                      class="py-1 px-3 flex items-center justify-center text-xs"
                    >
                      <span v-if="item.icon" class="material-icons text-xs">
                        {{ item.icon }}
                      </span>
                    </div>

                    <div class="flex w-48 items-end">
                      <span
                        class="text-sm"
                        :class="[sidebarOpen ? 'hidden' : '']"
                        >{{ item.name }}</span
                      >
                    </div>
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-auto">
          <div
            class="flex items-center cursor-pointer mb-6 border-t border-b rounded-md"
            tabindex="0"
          >
            <div class="pl-4 my-4">
              <div
                class="rounded-full h-10 w-10 flex items-center justify-center"
              >
                <img
                  v-if="userName?.profile?.profile_image"
                  alt="Profile"
                  @error="setAltImg"
                  :src="userName.profile?.profile_image"
                  class="mx-auto object-cover rounded-full w-full h-full"
                />
                <div
                  v-else
                  class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
                >
                  <!-- <span class="font-medium text-gray-600 dark:text-gray-300"
                    >{{ userName?.profile?.first_name?.charAt(0) }}
                    {{ userName?.profile?.last_name?.charAt(0) }}
                  </span> -->
                  <span class="font-medium text-gray-600 dark:text-gray-300"
                    >{{ userName?.email?.charAt(0) }}
                  </span>
                </div>
              </div>
            </div>

            <p class="text-darkGrey text-sm mx-3">
              <!-- <strong class="text-black">{{
                userName?.profile?.first_name
              }}</strong> -->
              <strong class="text-black">{{
                userName?.email?.slice(0, 3)
              }}</strong>
            </p>
            <div class="cursor-pointer text-darkGrey flex justify-end w-28">
              <span class="material-icons" @click="logoutUser"> logout </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Sidebar ends -->
    <!-- Content Start -->
    <central-card>
      <slot></slot>
    </central-card>
    <!-- Content Ends -->
  </div>
</template>

<script>
export default {
  emits: ["user-logout"],
  props: {
    userName: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      categories: {
        // humanResourcesGroup: {
        //   name: "Human Resources",
        //   icon: "groups",
        //   open: false,
        //   link: "ma-new-hire",
        //   items: [
        //     {
        //       name: "Recruitment",
        //       link: "ma-recruitment",
        //     },
        //   ],
        // },
        // accountingNFinanceGroup: {
        //   name: "Accounting & Finance",
        //   icon: "account_balance",
        //   open: false,
        //   query: "profile",
        //   link: "ma-profile",
        //   items: [{ name: "Payroll", link: "" }],
        // },
        // complianceGroup: {
        //   name: "Compliance",
        //   icon: "assignment_turned_in",
        //   open: false,
        //   link: "ma-profile",
        //   items: [{ name: "Compliance", link: "" }],
        // },
        // telephoneITGroup: {
        //   name: "Telephone & IT",
        //   icon: "fax",
        //   open: false,
        //   link: "ma-profile",
        //   items: [{ name: "", link: "" }],
        // },
        monitoringOfPracticeGroup: {
          name: "MoPF",
          icon: "blur_on",
          open: false,
          link: "",
          items: [
            {
              name: "Dashboard",
              link: "ma-monitoringOfPracticeFunctionsDashboard",
              icon: "circle",
            },
            {
              name: "Patient Survey",
              link: "ma-MOPFPatientSurveyDash",
              icon: "circle",
            },
            {
              name: "QOF",
              link: "ma-QofDash",
              icon: "circle",
            },
            {
              name: "Model Day",
              link: "ma-ModelDayDash",
              icon: "circle",
            },
            {
              name: "Compliance Calendar",
              link: "ma-ComplianceDashboard",
              icon: "circle",
            },
            {
              name: "Complaints",
              link: "ma-monitoringOfPracticeFunctionComplaintsDashboard",
              params: { isManager: 1 },
              icon: "circle",
            },
            {
              name: "Compliments",
              link: "ma-monitoringOfPracticeFunctionComplimentDashboard",
              params: { isManager: 1 },
              icon: "circle",
            },
          ],
        },
        // premisesNEstatesGroup: {
        //   name: "Premises & Estates",
        //   icon: "real_estate_agent",
        //   open: false,
        //   link: "ma-profile",
        //   items: [{ name: "", link: "" }],
        // },
        // centralisedClinicalActivityGroup: {
        //   name: "Centralised Clinical Activity",
        //   icon: "health_and_safety",
        //   open: false,
        //   link: "ma-centralisedClinicalActivityDashboard",
        //   items: [{ name: "", link: "" }],
        // },
      },
      imageError: false,
      assginedPractices: parseInt(localStorage.getItem("assigned_Practices")),
    };
  },
  methods: {
    sidebarHandler() {
      localStorage.setItem("sidebarOpen", !this.sidebarOpen);
      this.$store.commit("profile/setSideBarPosition", !this.sidebarOpen);
    },
    setAltImg() {
      this.imageError = true;
    },

    logoutUser() {
      this.$emit("user-logout");
    },
  },
  computed: {
    sidebarOpen() {
      return this.$store.getters["profile/getSidbarOpen"];
    },
  },
};
</script>

<style scoped>
.router-link-active span div,
.router-link-active span .material-icons {
  color: #00a499;
}
.active-tab {
  color: #00a499;
}

#forHeight {
  height: calc(100vh - 64px);
}
</style>
